<template>
  <v-card dark>
    <v-img
      :src="require(`@/assets/${bannerimage}`)"
      class="grey lighten-2"
      height="220"
      width="100%"
      gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,1)"
    >
      <v-row class="fill-height pa-3 text-center d-flex align-center">
        <v-col class cols="12">
          <h3 class="mb-1 display-1">{{ bannerhead }}</h3>
          <p class="font-weight-light title mb-8">
            {{ bannertext }}
          </p>
          <!-- <v-btn color="primary" to="/signup" class="mt-4" v-if="!user" depressed>INIZIA ORA</v-btn> -->
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeBanner",

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  props: {
    bannerhead: {
      type: String,
      default: "Dear Mama",
    },
    bannertext: {
      type: String,
      default: "il documentario",
    },
    bannerimage: {
      type: String,
      default: "special-content.jpg",
    },
  },
};
</script>
